@mixin font-oswald {
  font-family: "Oswald", "Roboto", sans-serif;
}
@mixin font-roboto {
  font-family: "Roboto", "Oswald", sans-serif;
}

* {
  @include font-roboto;
}

$color-dark-purple: #281b54;
$color-darker-purple: #201254;
$color-light-purple: #d7c6ff;
$color-lighter-purple: #e5daff;
$color-purple: #372969;
$color-bright-purple: #8447ff;
$color-teal: #58bd98;
$color-dark-grey: #333333;
$color-med-grey: #999999;
$color-light-grey: #ececec;
$color-red: #ff5151;
