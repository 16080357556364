@import "../../common.scss";

.footer {
  background-color: $color-dark-purple;
  padding: 26px;
  display: flex;
  justify-content: center;
  align-items: center;

  .help,
  .help * {
    color: white;
  }
}
