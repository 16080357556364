@import "../../common.scss";

.navbar {
  position: relative;
  padding-left: 30px;
  padding-right: 40px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  @include font-oswald;
  font-style: normal;
  color: #ffffff;
  align-items: center;
  background-color: $color-darker-purple;
  z-index: 1;
  width: 100%;

  & > * {
    width: 50%;
  }

  & > :last-child {
    justify-content: flex-end;
  }

  .navbar-logo {
    display: flex;
    align-items: center;
  }

  .navbar-logo-img {
    height: 60px;
  }

  .navbar-tabs {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.1em;
    line-height: 27px;
  }
}
