@import "../../common.scss";

.zener-details-mutable-op-mode-option {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.cont > div > div {
    color: $color-teal;
  }

  & > div > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  & svg {
    margin-right: 3px;
  }
}

.zener-details-mutable {
  width: 100%;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }

  & > .action-row {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
