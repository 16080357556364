@import "../../common.scss";

.sign-in-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3def8;
  display: flex;
  flex-flow: column nowrap;

  .sign-in-logo {
    width: 300px;
    margin-bottom: 20px;
  }

  .sign-in-form {
    box-sizing: border-box;
    padding: 20px;
    min-width: 30%;
    margin-bottom: 20px;
  }
  .sign-in-form > form > * {
    margin-bottom: 20px;
  }

  .sign-in-submit-btn {
    background-color: #43c59e !important;
    color: white !important;
    float: right;
  }

  .sign-in-title {
    @include font-oswald;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 25px;
    color: $color-dark-purple;
  }

  .forgot-pass-submit-btn {
    margin: 20px;
    background-color: $color-darker-purple !important;
    color: white !important;
    float: right;
  }

  .sign-in-action-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
  }

  .create-account-footer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    min-width: 30%;
    text-align: center;
  }
}
