@import "../../common.scss";

.dashboard {
  @include font-oswald;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 30px;

  .dashboard-middle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
    width: 80%;
  }

  .dash-search {
    width: 40%;
  }

  .middle-notice {
    margin-top: 15px;
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: $color-purple;
  }

  .middle-zeners {
    width: 100%;
  }
}
