@import "../../common.scss";

.account {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include font-oswald;
  color: $color-teal;
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.1em;

  .content-wrapper {
    width: 918px;
    padding: 60px 0;

    .title-span {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.1em;
      color: #372969;
      margin-right: 3em;
      margin-left: 1em;
      min-width: 12%;
    }

    .title-span-etc {
      margin-left: 1.6em;
    }

    .user-span {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.1em;
      color: #372969;
    }

    .account-settings-details {
      height: 247px;
      border: 3px solid #ececec;
      box-sizing: border-box;
      border-radius: 8px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .details-title {
      border-bottom: 2px solid #ececec;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
    }

    .details-title button {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.2em;
      color: #281b54;
      border: none;
      background: none;
      text-decoration: underline;
      text-underline-offset: 5%;
      margin-right: 1.5em;
      cursor: pointer;
    }

    .details-title-left {
      display: flex;
      flex-direction: column;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
      color: #c4c4c4;
    }
    .details-user {
      border-bottom: 2px solid #ececec;
      height: 80px;
      display: flex;
      align-items: center;
    }

    .details-pass {
      height: 80px;
      display: flex;
      align-items: center;
    }

    .details-pass input {
      min-width: 119.01px;
      min-height: 8.06px;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.1em;
      color: #372969;
      border: none;
    }

    .account-billing-price {
      margin-top: 38px;
      border: 3px solid #ececec;
      box-sizing: border-box;
      border-radius: 6.68161px;
      width: 249.69px;
      height: 284.9px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .account-billing-price-amount {
      font-size: 45px;
      line-height: 68px;
      letter-spacing: 0.1em;
      font-weight: 600;
    }

    .account-billing-price-desc {
      color: rgba(55, 41, 105, 0.35);
      font-size: 15.0336px;
      line-height: 22px;
      letter-spacing: 0.1em;
    }
    .account-billing-options {
      margin-top: 72px;
      font-family: Roboto;
      font-style: italic;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
      color: rgba(88, 189, 152, 0.75);
      border: none;
      text-decoration: underline;
      background-color: transparent;
    }
  }
}
