@import "../../common.scss";

.zener-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(white, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    box-sizing: border-box;
  }

  .teal {
    color: $color-teal;
  }

  .purple {
    color: $color-bright-purple;
  }

  & > .content-container {
    background-color: white;
    box-shadow: -7.31px 23.56px 32.5px 0px #37296924;
    padding: 20px;
    display: flex;
    flex-flow: row nowrap;
    max-width: 40%;
    width: 100%;
    border: 2.44px solid $color-light-grey;
    border-radius: 8px;

    & > .table-container {
      flex: 1;
    }

    & > .edit-container {
      & > .edit-btn {
        background: none;
        border: none;
        text-decoration: underline;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
