@import "../../common.scss";

.disinfection-time-plot {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  position: relative;

  & > .plot-title {
    align-self: flex-start;
    padding-left: 24px;
    font-weight: 400;
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  & > .plot-subtitle {
    align-self: flex-start;
    padding-left: 24px;
    font-weight: 400;
    font-size: 14px;
    margin-top: 6px;
  }

  & > .line-legend {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    & > .line-line {
      background-color: $color-teal;
      width: 30px;
      height: 2px;
      margin-right: 6px;
    }
  }
}
