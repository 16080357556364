@import "../../common.scss";

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.translucent {
    background-color: rgba(white, 0.2);
  }

  & > .loading-overlay-container {
    background: rgba(61, 44, 119, 0.8);
    box-shadow: -13px 15px 14px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12px);
    border-radius: 10px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loading-image {
      animation: rotate 1s linear infinite;
      width: 80px;
    }

    .loading-message {
      @include font-oswald;
      color: white;
      margin-top: 2rem;
      padding: 0.1em;
      border-radius: 999px;
      font-size: 20px;
      font-style: italic;
    }
  }
}
