@import "../../common.scss";

.forgot-password-modal-container {
  .forgot-password-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.7);
  }

  .forgot-password-modal {
    @include font-oswald;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    z-index: 10;
    position: absolute;
    top: 35%;
    left: 35%;
    background: #ffffff;
    border: 2.43756px solid #ececec;
    box-sizing: border-box;
    box-shadow: -7.31267px 23.5631px 32.5008px rgba(55, 41, 105, 0.14);
    border-radius: 8.12519px;
  }

  .forgot-password-titlespace {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 95%;
    justify-content: space-between;
    border-bottom: 2.43756px solid #f3f3f3;
    margin-bottom: 4%;
  }

  .titlespace-span-container {
    display: flex;
    flex-direction: row;
    flex-wrap: none;
    width: 75%;
    padding: 3%;
    align-items: center;
  }

  .titlespace-span {
    font-style: normal;
    font-weight: bold;
    font-size: 150%;
    line-height: 100%;
    letter-spacing: 0.1em;
    color: $color-bright-purple;
  }

  .close-modal {
    background: none;
    border-radius: 10px;
    width: 5%;
    height: 5%;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 90%;
    color: $color-bright-purple;
    border: none;
    margin: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .close-modal:hover {
    color: $color-darker-purple;
  }

  .forgot-password-heading {
    @include font-oswald;
    font-style: normal;
    font-weight: 700;
    font-size: 100%;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $color-purple;
  }

  .forgot-password-email {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  .email-input {
    margin-top: 1%;
    margin-bottom: 1%;
    border: 1px solid rgba(55, 41, 105, 0.14);
    border-radius: 999px;
    padding-left: 3%;
    padding-bottom: 1%;
    padding-top: 1%;
    outline-color: rgba(55, 41, 105, 0.8);
  }
  .forgot-password-email-action {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 0;
  }

  .action-button {
    @include font-roboto;
    background: white;
    border: 3px solid $color-light-purple;
    border-radius: 10px;
    min-height: 40px;
    font-weight: bold;
    padding: 0 10px;
    font-size: 16px;
    letter-spacing: 0.1em;
    color: $color-bright-purple;
    cursor: pointer;
  }

  .action-button:hover {
    background: #d7c6ff;
    border: none;
  }
  .reset-with-code {
    width: 90%;
  }

  .code-reset-info {
    font-family: Roboto;
    font-style: italic;
    font-weight: normal;
    font-size: 70%;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: $color-purple;
  }

  .input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 10%;
    margin-bottom: 5%;
    justify-content: space-between;
  }
  .input-field {
    border: 1px solid rgba(55, 41, 105, 0.14);
    border-radius: 999px;
    padding-left: 3%;
    padding-bottom: 1%;
    padding-top: 1%;
    width: 50%;
    outline-color: rgba(55, 41, 105, 0.8);
  }
}
