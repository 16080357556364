@import "../../common.scss";

.zener-details-immutable {
  & > .header-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    & > .name {
      margin: 0;
    }

    & > .status {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      & > .status-circle {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 999px;
      }

      & > .status-text {
        padding-left: 3px;
      }
    }
  }

  & > .description {
    margin-top: 0;
    padding-bottom: 20px;
    color: $color-med-grey;
  }

  & > .prop-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    & > .prop-value {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      & > .op-mode-icon {
        margin-right: 3px;
      }

      & > .loading-spinner {
        animation: rotate 1s linear infinite;
        width: 20px;
      }
      & > .prop-error {
        color: red;
      }
    }
  }

  & > .action-row {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
