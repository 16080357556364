body,
html {
  margin: 0;
  padding: 0;
  height: 100vh;
}

#root {
  height: 100vh;
}

.app {
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  .content-wrapper {
    flex: 1;

    .content-wrapper-inner {
      .no-devices {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80%;
      }
    }
  }
}

.app-page-container {
  height: calc(100vh - 80px);
}
