@import "../../common.scss";

.searchbar {
  .searchbar-label {
    @include font-oswald;
    font-weight: 700;
    color: $color-dark-purple;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .searchbar-input-container {
    background-color: $color-lighter-purple;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 999px;
    margin-top: 5px;
  }

  .searchbar-input {
    flex: 1;
    background: none;
    border: none;
    max-height: 18px;
    font-style: italic;
    color: $color-bright-purple;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.17em;
    border: none;
    outline: none;
  }

  .searchbar-input:focus {
    outline-offset: -200px;
  }

  .searchbar-icon {
  }
}
