@import "../../common.scss";

.col-info {
  padding: 10px;

  & > * {
    margin: 0;
  }

  & > .title > .icon {
    transform: translateY(2px);
    margin-right: 6px;
  }

  & > .description {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.table-wrapper {
  width: 100%;
  overflow: hidden;

  .table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    th {
      color: rgba(white, 0.9);

      & > .header-cell {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end !important;
        align-items: center;

        & > .col-info-popover {
          margin-left: 6px;
        }

        & > .sort-dir-icon {
          font-size: 24px;
        }

        & > .sort-icon {
          margin-left: 6px;
          font-size: 18px;
        }
      }

      &:first-child > .header-cell {
        justify-content: flex-start !important;
      }
    }

    .row {
      cursor: pointer;
    }
  }

  .header {
    background-color: $color-dark-purple;
    color: white;
    height: 33px;
    border-radius: 4px 4px 0 0;
  }

  .name-cell {
    text-align: left;

    & > * {
      display: block;
    }

    & > .name {
      color: $color-dark-purple;
      @include font-oswald;
      font-weight: 500;
    }

    & > .description {
      color: $color-med-grey;
      font-weight: 400;
    }
  }

  .height-cell {
    text-align: right;
  }

  .enabled-cell {
    text-align: right;
  }

  .op-mode-cell {
    display: flex;
    flex-flow: row-nowrap;
    justify-content: flex-end;
    align-items: center;

    & > .op-mode-icon {
      margin-right: 6px;
      font-size: 20px;
    }

    & > .label {
      font-weight: 500;
    }
  }

  .status-cell {
    display: flex;
    flex-flow: row-nowrap;
    justify-content: flex-end;
    align-items: center;

    & > .status-circle {
      height: 7px;
      width: 7px;
      border-radius: 999px;
      margin-right: 6px;
    }
  }

  .date-cell {
    color: $color-dark-grey;

    & > * {
      display: block;
      text-align: right;
    }
  }
}
