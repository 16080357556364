.column-info-popover-content {
  pointer-events: none;
}

.column-info-popover {
  & > .icon-popover {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
