.reports-root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .reports-content-container {
    width: 100%;
    max-width: 1200px;
    padding-top: 60px;
  }

  .reports-report {
    margin-top: 20px;
  }

  .reports-dg-rows {
    width: 100%;
  }

  .reports-dg-r {
    display: flex;
    flex-flow: row nowrap;

    & > * {
      flex: 1;
    }
  }

  .reports-metric-item {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 220px;
    margin: 10px;
  }

  .reports-metric-item-value {
    display: block;
    font-size: 50px;
    margin-bottom: 10px;
  }

  .reports-metric-item-label {
    display: block;
    padding: 15px;
    text-align: center;
  }

  .dft-plot {
    margin: 10px;
    height: 100%;
  }

  .caz-plot {
    margin: 10px;
    height: 100%;
  }

  & > .download-btn {
    position: absolute;
    top: 30px;
    right: 30px;
    display: none;

    & > .loading-image {
      animation: rotate 1s linear infinite;
      width: 20px;
      padding: 0 30px;
    }
  }
}
