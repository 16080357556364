@import "../../common.scss";

.navbar-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-direction: row;

  .navbar-user-email-field {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 16px;
    padding-bottom: 16px;
    background-image: linear-gradient($color-teal, $color-teal);
    background-position: 0 100%;
    background-size: 0 5px;
    background-repeat: no-repeat;
    transition: background-size 0.3s, background-position 0s 0.3s;
  }
  .navbar-user-email-field[datatype="underline"] {
    background-position: 0 100%;
    background-size: 100% 5px;
  }

  .navbar-button {
    box-shadow: none;
    outline: none;
    background: none;
    border: none;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.1em;
    line-height: 27px;
    width: auto;
    align-self: start;
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-right: 20px;
    padding-left: 20px;
    white-space: nowrap;
  }

  .navbar-button:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-dropdown {
    position: absolute;
    width: 425px;
    transform: translateX(10%);
    top: 80px;
    right: 160px;
    display: flex;

    flex-direction: column;
    background-color: $color-darker-purple;
    position: absolute;
  }
  .navbar-dropdown-offclick-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: none;
  }

  .navbar-dropdown-link {
    @include font-oswald;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    text-decoration: none;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.1em;
    margin-bottom: 3.64px;
    margin-left: 4%;
  }

  .navbar-dropdown-break {
    width: 80%;
  }
}
