.uvx-button {
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  padding: 8px 16px;
  font-size: 20px;
  outline: 0;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.uvx-button-press-animation {
  animation: btn-press 150ms ease-out forwards;
}

.uvx-button-primary-color {
  background-color: #201254;
  color: white;
}

.uvx-button-primary-color:hover {
  background-color: #201254aa;
  color: white;
}

.uvx-button-secondary-color {
  background-color: #43c59e;
  color: #35393c;
}

.uvx-button-full-width {
  width: 100%;
}

.uvx-button:disabled {
  background-color: #666666;
  color: white;
}

@keyframes btn-press {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(0.95, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}
